import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { InputTypeEnum } from '../../enums/input-type.enum';
import { SelectOption } from "../../models/select-option";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
})
export class InputComponent {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() type: number = 1;
  @Input() values: SelectOption[] = []; // Only for select type
  @Input() rowsNumber: number = 5; // Only for textarea type
  @Input() showValidationIcon: boolean = false; // Only for text type

  INPUT_TYPE = InputTypeEnum;
}
