import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './core/layout/layout.module';
import { SpinnerModule } from './core/spinner/spinner.module';
import { TranslationModule } from './core/translation.module';
import { SharedModule } from './shared/shared.module';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { environment } from '../environments/environment';

const notifierConfig: NotifierOptions = {
  theme: 'primeng',
  position: {
    horizontal: {
      position: 'right',
    },
  },
};
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    LayoutModule,
    SpinnerModule,
    NotifierModule.withConfig(notifierConfig),
    TranslationModule,
    NgxMatomoTrackerModule.forRoot({ trackerUrl: environment.urlMatomo, siteId: environment.idmatomo }),
    NgxMatomoRouterModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
