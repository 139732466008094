import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './core/errors/components/error/error.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        // Route par défaut
        path: '',
        redirectTo: '/landing',
        pathMatch: 'full',
      },
      {
        path: 'client',
        loadChildren: () => import('./features/client/client.module').then(m => m.ClientModule),
      },
      {
        path: 'blade',
        loadChildren: () => import('./features/blade/blade.module').then(m => m.BladeModule),
      },
      {
        path: 'landing',
        loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./features/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: '**',
        component: ErrorComponent,
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
