import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-show-picture',
  template: `
    <img [src]="fileUrl" alt="image" class="tw-w-full tw-object-cover tw-rounded-2xl">
    <i class="pi pi-times tw-absolute tw-top-2 tw-right-2 tw-font-bold tw-text-primary tw-cursor-pointer tw-rounded-full tw-p-2 tw-bg-white" (click)="close()"></i>
  `,
})
export class ShowPictureComponent {

  fileUrl: string;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.fileUrl = this.dialogConfig.data;
  }

  close(): void {
    this.ref.close();
  }
}
