/* eslint-disable @typescript-eslint/naming-convention */
export class Constants {
  // Autentification
  static readonly AUTH_TOKEN: string = 'auth_token';
  static readonly AUTH_USER: string = 'auth_user';

  // Routes
  static readonly ROUTE_DEFAULT: string[] = ['/'];
  static readonly ROUTE_LOGIN: string[] = ['authentication', 'login'];

  // Langues
  static readonly LANG_DEFAULT: string = 'fr';
  static readonly LANG_SUPPORTED: string[] = ['fr'];

  // Chemins
  static readonly PATH_CONFIG: string = 'assets/config/';
  static readonly PATH_TRANSLATIONS: string = 'assets/translations/';

  static readonly PUBLIC_ROUTES: string[] = ['userCredentials']
}
