// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  place-content: space-between;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.logo-container {
  display: flex;
  cursor: pointer;
  align-items: center
}

.logo-text {
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: 0.3125rem;
  font-family: "Oswald", sans-serif
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/header/header.component.scss"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,WAAA;EAAA,mBAAA;EAAA,4BAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,oBAAA;EAAA,qBAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,aAAA;EAAA,eAAA;EAAA;AAAA;;AAIA;EAAA,eAAA;EAAA,iBAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA,yBAAA;EACA;AADA","sourcesContent":[".header-container {\n  @apply tw-w-full tw-flex tw-flex-row tw-place-content-between tw-px-10 tw-py-5 tw-bg-white;\n}\n\n.logo-container {\n  @apply tw-flex tw-items-center tw-cursor-pointer;\n}\n\n.logo-text {\n  @apply tw-tracking-ultra-wide tw-font-normal\ttw-text-2xl tw-cursor-pointer;\n  font-family: 'Oswald', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
