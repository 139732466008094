import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription, tap } from "rxjs";
import { ShowPictureComponent } from "./show-picture/show-picture.component";

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.scss"],
  providers: [DialogService]
})
export class ImageUploaderComponent implements OnInit {

  @Input() set initialPicture(value: File | string) {
    if (value instanceof File && !this.file) {
      this.file = value;
      this.fileUrl = URL.createObjectURL(this.file);
      this.imageUploaded.emit({ file: this.file, modified: false });
    } else if (typeof value === 'string' && !this.file) {
      this.dataURLtoFile(value);
    }
  }
  @Input() initialPictureSize: number;
  @Input() initialPictureName: string;
  @Input() maxFileSize: number = 52428800;
  @Output() imageUploaded = new EventEmitter<{file: File, modified: boolean}>();
  @Output() nameChanged = new EventEmitter<string>();

  file: File;
  fileUrl: string;
  form: FormGroup;
  modifing: boolean = false;
  savedName: string;

  dialogRef: DynamicDialogRef | undefined;
  subscriptions$: Subscription;

  get fileName(): FormControl {
    return this.form.get('fileName') as FormControl;
  }

  constructor(
    private dialogService: DialogService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      fileName: [this.initialPictureName ? this.initialPictureName.split('.')[0] : ""],
    });

    this.fileName.valueChanges.pipe(
      tap((value) => {
        this.nameChanged.emit(value + this.getFileExtension());
      })
    ).subscribe();
  }

  dataURLtoFile(b64String: string): void {
    const url = 'data:image/png;base64,' + b64String;

    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'image.png', { type: 'image/png' });
        this.file = file;
        this.fileUrl = URL.createObjectURL(this.file);
        this.imageUploaded.emit({ file: this.file, modified: false });
      });
  }

  fileSelected(event): void {
    if (event.currentFiles.length) {
      this.file = event.currentFiles[0];
      this.imageUploaded.emit({ file: this.file, modified: true });
      this.fileUrl = URL.createObjectURL(this.file);
      this.fileName.patchValue(this.file.name.split('.')[0]);
    }
  }

  onFileChoose(event): void {
    const file = event.target.files[0];

    if (event.target.files.length && file.size <= this.maxFileSize && (file.type === "image/jpeg" || file.type === "image/tiff" || file.type === "image/png")) {
      this.file = file;
      this.imageUploaded.emit({ file: this.file, modified: true });
      this.fileUrl = URL.createObjectURL(this.file);
      this.fileName.patchValue(this.file.name.split('.')[0]);
    }
  }

  clearFile(): void {
    this.file = null;
    this.imageUploaded.emit({ file: null, modified: true });
    this.fileUrl = null;
    this.initialPicture = null;
    this.initialPictureName = null;
    this.initialPictureSize = null;
    this.modifing = false;
  }

  showPicture(): void {
    this.dialogRef = this.dialogService.open(ShowPictureComponent, {
      styleClass: 'show-picture-dialog',
      width: '50vw',
      data: this.fileUrl,
    });
  }

  getFileExtension(): string {
    const extension = this.initialPictureName 
      ? this.initialPictureName.substring(this.initialPictureName.lastIndexOf('.'))
      : this.file.name.substring(this.file.name.lastIndexOf('.'))

    return extension;
  }
}
