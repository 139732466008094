import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
})
export class MenuItemComponent {
  @Input() key: string | undefined;
  @Input() icon?: string;
  @Input() nav?: string[]; // Liens internes
  @Input() url?: string; // Liens externes
}
