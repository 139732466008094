import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';

import { AppRoutingModule } from '../../app-routing.module';
import { IconPipe } from '../../shared/pipes/icon.pipe';
import { PrimengModule } from '../primeng.module';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    MenuItemComponent,
    SidebarComponent,
  ],
  imports: [
    IconPipe,
    CommonModule,
    PrimengModule,
    AppRoutingModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'layout',
    },
  ],
})
export class LayoutModule { }
