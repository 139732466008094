import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject,Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '../notification/notifier.service';

// Intercepteur d'erreurs HTTP
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private router: Router = inject(Router);
  private notifier: NotificationService = inject(NotificationService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorKey: string = '';

        // Pas de connexion à internet
        if (!navigator.onLine) {
          errorKey = 'errors.interceptor.offline';
        }
        // Erreur client
        else if (error.error instanceof ErrorEvent) {
          errorKey = 'errors.interceptor.client';
        }
        // Erreur serveur
        else {
            // Cas erreurs détaillées, affichage des erreurs et pas de message générique
            if (error.error?.messages?.length > 0) {
              for(const messageKey of error.error.messages) {
                this.notifier.error(`errors.server.${messageKey}`);
              }
            }
            else {
              errorKey = 'errors.interceptor.server';
            }
        }

        if (errorKey) {
          this.notifier.error(errorKey); // Déjà mis dans le scope correspondant
        }

        return throwError(() => error);
      }),
    );
  }


}
