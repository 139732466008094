import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() menuOpened: boolean | undefined;
  @Output() toggleMenu: EventEmitter<void> = new EventEmitter<void>();

  menu() {
    this.toggleMenu.emit();
  }
}
