import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-display',
  templateUrl: './text-display.component.html',
})
export class TextDisplayComponent {

  @Input() labelSizeClass = 'tw-w-32';
  @Input() labelValue = 'Label value';
  @Input() value = 'Value';
}
