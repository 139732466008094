import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { buildUrl } from 'build-url-ts';
import { Observable, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Constants } from '../../shared/constants/app.constants';

interface IConfig {
  apiUrl: string;
  version: string;
}

@Injectable()
export class ConfigService {
  private http: HttpClient = inject(HttpClient);

  private config: IConfig; // TODO: remplacer par environnement

  get version(): string {
    return this.config.version;
  }

  // Récupération de la configuration
  initConfig(): Observable<IConfig> {
    const path = `${Constants.PATH_CONFIG}${environment.name}.json`;
    return this.http.get<IConfig>(path).pipe(
      tap((res: IConfig) => {
        this.config = res;
        this.config.version = require('../../../../package.json').version;
      }),
    );
  }

  // URL du backend avec des chemins relatifs en plus (exemple : apiUrl('foo', 'bar') => http://example.com/foo/bar)
  apiUrl(...path: (string | number)[]): string {
    return buildUrl(this.config?.apiUrl, { path: path.join('/') });
  }
}
