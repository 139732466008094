// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.6);
}

.spinner {
  width: 40px;
  height: 40px;
  top: 35%;
  position: relative;
  margin: 100px auto;
}`, "",{"version":3,"sources":["webpack://./src/app/core/spinner/components/spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,oCAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,QAAA;EACA,kBAAA;EACA,kBAAA;AACF","sourcesContent":[".preloader {\n  position: absolute;\n  margin: 0 auto;\n  width: 100%;\n  height: 100%;\n  z-index: 100;\n  background: rgba(255, 255, 255, 0.6);\n}\n\n.spinner {\n  width: 40px;\n  height: 40px;\n  top: 35%;\n  position: relative;\n  margin: 100px auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
