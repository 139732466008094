import { inject,Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NotifierService as Notifier } from 'angular-notifier'; // Librairie à changer au besoin (par ex. @ngngeat/hot-toast)

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notifier: Notifier = inject(Notifier);
  private transloco: TranslocoService = inject(TranslocoService);

  success(key: string) {
    return this.notifier.notify('success', this.transloco.translate(key));
  }

  info(key: string) {
    return this.notifier.notify('info', this.transloco.translate(key));
  }

  warning(key: string) {
    return this.notifier.notify('warning', this.transloco.translate(key));
  }

  error(key: string) {
    return this.notifier.notify('error', this.transloco.translate(key));
  }
}
