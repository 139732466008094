import { Component } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
})
export class LayoutComponent {
  menuOpened: boolean = true;

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }
}
