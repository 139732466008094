import { Component } from '@angular/core';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'digitrock';

  constructor(
    private matomoTracker: MatomoTracker,
  ) {
    this.matomoTracker.trackEvent('Catégorie', 'Action');
  }

}
