import { Component, inject } from '@angular/core';

import { SpinnerService } from '../../../core/spinner/services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  spinner: SpinnerService = inject(SpinnerService);
}
