import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-input-text",
  templateUrl: "./input-text.component.html",
})
export class InputTextComponent {
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() isDisabled: boolean = false;
  @Input() showValidationIcon: boolean;
}
