import { Component, Input, OnInit } from "@angular/core";
import { Appuser } from "../../models/appuser";
import { ClientService } from "./client.service";
import { first, tap } from "rxjs";

@Component({
  selector: "app-user-subheader",
  templateUrl: "./user-subheader.component.html",
  styleUrls: ["./user-subheader.component.scss"],
})
export class UserSubheaderComponent implements OnInit {
  @Input() user: Appuser;

  logo: string;

  constructor(
    private clientService: ClientService,
  ) {}

  ngOnInit(): void {
    this.clientService.getClientById(this.user.client.id).pipe(
      first(),
      tap(client => {
        this.user.client = client
        this.logo = `data:image/png;base64,${client.logo}`;
      }),
    ).subscribe();
  }
}
