import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { ConfigService } from '../core/config/config.service';
import { AuthInterceptor } from '../core/interceptors/auth.interceptor';
import { ErrorInterceptor } from '../core/interceptors/error.interceptor';
import { SpinnerInterceptor } from '../core/interceptors/spinner.interceptor';
import { PrimengModule } from '../core/primeng.module';
import { ClientSubheaderComponent } from './components/client-subheader/client-subheader.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { InputSelectComponent } from './components/input/input-select/input-select.component';
import { InputTextComponent } from './components/input/input-text/input-text.component';
import { InputTextAreaComponent } from './components/input/input-textarea/input-text-area.component';
import { InputComponent } from './components/input/input.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { TextDisplayComponent } from './components/text-display/text-display.component';
import { UserSubheaderComponent } from './components/user-subheader/user-subheader.component';
import { MegabytesPipe } from './pipes/megabytes.pipe';
import { LogoutComponent } from './components/logout/logout.component';

const appConfigFactory = (config: ConfigService) => () => config.initConfig();

const sharedComponents = [
  ErrorMessageComponent,
  HeaderComponent,
  FooterComponent,
  InputComponent,
  InputTextComponent,
  InputSelectComponent,
  InputTextAreaComponent,
  SearchbarComponent,
  ImageUploaderComponent,
  TextDisplayComponent,
  UserSubheaderComponent,
  ClientSubheaderComponent,
  LogoutComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PrimengModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslocoModule,
    MegabytesPipe,
  ],
  declarations: [
    sharedComponents,
  ],
  exports: [
    sharedComponents,
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
})
export class SharedModule { }
