import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-input-text-area",
  templateUrl: "./input-text-area.component.html",
})
export class InputTextAreaComponent {
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() isDisabled: boolean;
  @Input() rowsNumber: number;
}
