import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Gestion du spinner d'attente
 *
 * @export
 * @class SpinnerService
 */
@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerSubject: Subject<boolean> = new Subject<boolean>();
  visible$: Observable<boolean> = this.spinnerSubject.asObservable();

  private calls: number = 0;

  show() {
    this.calls++;
    this.refresh();
  }

  hide() {
    this.calls = Math.max(0, this.calls - 1); // Evite les nombres négatifs
    this.refresh();
  }

  reset() {
    this.calls = 0;
    this.refresh();
  }

  private refresh() {
    this.spinnerSubject.next(this.calls > 0);
  }
}
