// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {

    width: 100%;

    place-content: space-between;

    --tw-bg-opacity: 1;

    background-color: rgb(18 38 51 / var(--tw-bg-opacity));

    padding-top: 2.5rem;

    padding-bottom: 2.5rem;

    padding-left: 3.5rem;

    padding-right: 3.5rem
}

.logo-text {

    margin-left: 1.25rem;

    font-size: 1.875rem;

    line-height: 2.25rem;

    letter-spacing: 0.3125rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.small-text {

    font-size: 0.875rem;

    line-height: 1.25rem;

    font-weight: 600;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.link-text {

    cursor: pointer;

    font-size: 1.125rem;

    line-height: 1.75rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/footer/footer.component.scss"],"names":[],"mappings":"AACE;;IAAA,WAAA;;IAAA,4BAAA;;IAAA,kBAAA;;IAAA,sDAAA;;IAAA,mBAAA;;IAAA,sBAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA,oBAAA;;IAAA,mBAAA;;IAAA,oBAAA;;IAAA,yBAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA,mBAAA;;IAAA,oBAAA;;IAAA,gBAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA,eAAA;;IAAA,mBAAA;;IAAA,oBAAA;;IAAA,oBAAA;;IAAA;AAAA","sourcesContent":[".footer {\n  @apply tw-w-full tw-place-content-between tw-py-10 tw-px-14 tw-bg-primary;\n}\n\n.logo-text {\n  @apply tw-text-3xl tw-text-white tw-tracking-ultra-wide tw-ml-5;\n}\n\n.small-text {\n  @apply tw-text-sm tw-text-white tw-font-semibold;\n}\n\n.link-text {\n  @apply tw-text-lg tw-text-white tw-cursor-pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
