import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SpinnerService } from '../spinner/services/spinner.service';

// Intercepteur pour ajouter un spinner à chaque requête HTTP
// TODO : Prévoir un moyen pour l'enlever pour certaines requêtes (ex. autocompletes)
@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private spinner: SpinnerService = inject(SpinnerService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    return next.handle(req).pipe(
      finalize(() => this.spinner.hide()),
    );
  }
}
