import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../../../core/config/config.service";
import { Client } from "../../../shared/models/client";

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private http: HttpClient = inject(HttpClient);
  private config: ConfigService = inject(ConfigService);

  getClientById(id: number): Observable<Client> {
    return this.http.get<Client>(this.config.apiUrl(`client/${id}`))
  }
}
