// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-button {

    position: absolute;

    top: 3.5rem;

    right: 0px;

    z-index: 50;

    border-radius: 0.5rem;

    border-width: 0px;

    --tw-bg-opacity: 1;

    background-color: rgb(148 174 190 / var(--tw-bg-opacity));

    padding-left: 1.25rem;

    padding-right: 1.25rem;

    padding-top: 0.625rem;

    padding-bottom: 0.625rem;

    font-weight: 600;

    --tw-text-opacity: 1;

    color: rgb(18 38 51 / var(--tw-text-opacity))
}

.logout-button:hover:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(95 134 157 / var(--tw-bg-opacity))
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/logout/logout.component.scss"],"names":[],"mappings":"AACI;;IAAA,kBAAA;;IAAA,WAAA;;IAAA,UAAA;;IAAA,WAAA;;IAAA,qBAAA;;IAAA,iBAAA;;IAAA,kBAAA;;IAAA,yDAAA;;IAAA,qBAAA;;IAAA,sBAAA;;IAAA,qBAAA;;IAAA,wBAAA;;IAAA,gBAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA,kBAAA;;IAAA;AAAA","sourcesContent":[".logout-button {\n    @apply tw-absolute tw-top-14 tw-right-0 tw-bg-blue-50 tw-text-primary tw-font-semibold tw-border-0 tw-px-5 tw-py-2.5 tw-rounded-lg tw-z-50;\n}\n\n.logout-button:hover {\n    @apply hover:tw-bg-blue-75;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
