import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
})
export class SearchbarComponent {
  @Input() placeholder: string;
  @Input() control: FormControl = new FormControl();
  @Output() inputChanged: EventEmitter<any>;

  constructor() {
    this.inputChanged = new EventEmitter<any>();
  }

  onKeyup(input: string) {
    this.inputChanged.emit(input);
  }
}
