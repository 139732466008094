import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, inject, isDevMode, NgModule } from '@angular/core';
import { getBrowserLang, provideTransloco, Translation, TRANSLOCO_LOADER, TranslocoLoader, TranslocoService } from '@ngneat/transloco';

import { Constants } from '../shared/constants/app.constants';

// Langue de l'utilisateur en fonction de celle du navigateur (ou celle par défaut si non trouvé) */
const translocoFactory = (transloco: TranslocoService) => () => {
  const userLang: string = Constants.LANG_SUPPORTED.includes(getBrowserLang()) ? getBrowserLang() : Constants.LANG_DEFAULT;
  transloco.setActiveLang(userLang);
  return transloco.load(userLang);
};

// Chargement des langues par module et scope */
class TranslocoHttpLoader implements TranslocoLoader {
  private http: HttpClient = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/translations/${lang}.json`);
  }
}

@NgModule({
  providers: [
    provideTransloco({
      config: {
        availableLangs: Constants.LANG_SUPPORTED,
        defaultLang: Constants.LANG_DEFAULT,
        fallbackLang: Constants.LANG_DEFAULT,
        missingHandler: {
          useFallbackTranslation: true,
        },
        failedRetries: 1,
        flatten: {
          aot: !isDevMode(),
        },
        prodMode: !isDevMode(),
      },
    }),
    {
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: translocoFactory,
      deps: [TranslocoService],
      multi: true,
    },
  ],
})
export class TranslationModule { }
