import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { PrimengModule } from '../../../../core/primeng.module'

@Component({
  standalone: true,
  selector: 'app-error',
  templateUrl: './error.component.html',
  imports: [ TranslocoModule, PrimengModule ],
})
export class ErrorComponent {
  location: Location = inject(Location);
}
