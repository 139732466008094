import { Component, Input, OnInit } from "@angular/core";
import { Client } from "../../models/client";

@Component({
  selector: "app-client-subheader",
  templateUrl: "./client-subheader.component.html",
  styleUrls: ["./client-subheader.component.scss"],
})
export class ClientSubheaderComponent implements OnInit {
  @Input() client: Client;

  logo: string;

  ngOnInit(): void {
    this.logo = `data:image/png;base64,${this.client.logo}`;
  }
}
