import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'megabytes',
  standalone: true,
})
export class MegabytesPipe implements PipeTransform {
  transform(value: number): string {
    return (value / 1048576).toFixed(2);
  }
}
