import { Component } from "@angular/core";
import { ConfigService } from "../../../core/config/config.service";
import { RoleEnum } from "../../enums/role.enum";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"]
})
export class LogoutComponent {

  role: string;
  initial: string;
  showLogout = false;

  constructor(
    private config: ConfigService,
  ) {
    if (localStorage.getItem('role') === RoleEnum.ADMIN) {
      this.role = "Administrateur";
    }
    this.initial = localStorage.getItem('initial').toUpperCase();
  }

  logout(): void {
    localStorage.clear();
    window.location.href = `${window.location.origin}/back/logout/cas`;
  }
}