import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { SelectOption } from "../../../models/select-option";

@Component({
  selector: "app-input-select",
  templateUrl: "./input-select.component.html",
})
export class InputSelectComponent {
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() isDisabled: boolean = false;
  @Input() values: SelectOption[] = [];
}
