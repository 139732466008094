// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headband {

    height: 4rem;

    --tw-bg-opacity: 1;

    background-color: rgb(202 215 223 / var(--tw-bg-opacity));

    padding-left: 9rem;

    padding-right: 9rem
}

.headband-content {

    margin-left: 2.5rem;

    display: flex;

    width: 100%;

    align-items: center;

    justify-content: space-between
}

.client-logo {

    height: 100%
}

.primary-text-xl {

    font-size: 1.25rem;

    line-height: 1.75rem;

    font-weight: 600;

    --tw-text-opacity: 1;

    color: rgb(18 38 51 / var(--tw-text-opacity))
}

.primary-text-2xl {

    font-size: 1.5rem;

    line-height: 2rem;

    font-weight: 600;

    letter-spacing: -0.025em;

    --tw-text-opacity: 1;

    color: rgb(18 38 51 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/user-subheader/user-subheader.component.scss"],"names":[],"mappings":"AACE;;IAAA,YAAA;;IAAA,kBAAA;;IAAA,yDAAA;;IAAA,kBAAA;;IAAA;AAAA;;AAIA;;IAAA,mBAAA;;IAAA,aAAA;;IAAA,WAAA;;IAAA,mBAAA;;IAAA;AAAA;;AAIA;;IAAA;AAAA;;AAIA;;IAAA,kBAAA;;IAAA,oBAAA;;IAAA,gBAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIF;;IAAA,iBAAA;;IAAA,iBAAA;;IAAA,gBAAA;;IAAA,wBAAA;;IAAA,oBAAA;;IAAA;AAAA","sourcesContent":[".headband {\n  @apply tw-bg-blue-25 tw-px-36 tw-h-16;\n}\n\n.headband-content {\n  @apply tw-flex tw-w-full tw-justify-between tw-items-center tw-ml-10;\n}\n\n.client-logo {\n  @apply tw-h-full;\n}\n\n.primary-text-xl {\n  @apply tw-text-xl tw-text-primary tw-font-semibold;\n}\n\n.primary-text-2xl {\n@apply tw-text-2xl tw-text-primary tw-font-semibold tw-tracking-tight;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
