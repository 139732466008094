import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, tap } from "rxjs";
import { Constants } from "../../shared/constants/app.constants";
import { ConfigService } from "../config/config.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private config: ConfigService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe( tap(() => {},
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    const splittedUrl = err.url.split('/');

                    if (err.status !== 401 || Constants.PUBLIC_ROUTES.some(route => route === splittedUrl[splittedUrl.length - 1])) {
                        return;
                    }
                    
                    window.location.href = `${window.location.origin}/back/login/cas`;
                }
            }
        ));
    }
}